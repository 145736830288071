import Error from "next/error";
import NextHead from "@xtribe/shared/components/lib/Head/NextHead";

export default function NotFound() {
  return (
    <>
      <NextHead
        title={"Seite nicht gefunden"}
        description={"Diese Seite konnte nicht gefunden werden"}
      />
      <Error statusCode={404} />
    </>
  );
}
